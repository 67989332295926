anticon {
    vertical-align: 0.1em !important;
}

.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner {
    border: 1px solid #1677FF;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-tree .ant-tree-node-content-wrapper,
:where(.css-dev-only-do-not-override-17a39f8).ant-tree .ant-tree-checkbox+span {
    cursor: unset;
}

