/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.1.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";


.react-drawer-drawer{
    width: 600px;
  }
  
  
  .layout {
    // background-color: #ccc;
    // border: 10px solid #f40000;
    // border-radius: 5px #0dcd6300;
    margin: 8px;
    // padding-left: 170px;
    // padding-right: 20px;
    
  }
  
  .layout div {
    // background-color: #93abd9;
    background-color: #ffffff;
    color: #d62f2f;
    // text-align: center;

    // display: flex;

    /* padding: -28px; */
    /* border-radius:20px; */
    // cursor: grab;
    // border: 1px solid #e4e4e4;
    // transition: box-shadow 0.1s;
    /* Add a transition for smooth hover effect */

    /* Apply a glow effect when hovering */
    &:hover {
      box-shadow: 0 0 8px 5px  #e4e4e4;
      /* Orange glow effect */
      // box-shadow: 2px 3px 15px 2px rgb(191, 194, 195); /* Orange glow effect */
    }
  }
  .grid-row {
    margin-bottom: 20px; /* Adjust the value as needed */
    margin-top: 100px;
  }
  
  //added on 23 / Nov / 23
  
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .toggle-btn {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  .toggle-btn input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: -5px;
    bottom: 0px;
    background-color: #d3d3d3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196f3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .toggle-label{
    margin-left: 12px;
  }



  .custom-grid {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    text-align:'center';
    // padding-right: 10px;
    /* Set the desired dimensions for the icon */
  }
  
  .custom-grid .bx {
    font-size: 44px; /* Set the desired font size for the icon */
  }
  
  .react-grid-item > .react-resizable-handle::after{
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 10px;
    height: 10px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(205, 17, 17, 0.5); /* Adjust the transparency as needed */
    z-index: 1; /* Ensure the overlay is above other elements */
  }


.loader  {
  animation: rotate 1s infinite;  
  height: 50px;
  width: 50px;
   position: fixed;
  top: 50%;
  left: 50%;
  bottom: 50%;
  /* transform: translate(-50%, -50%); */
  align-content: center;
}

.loader:before,
.loader:after {   
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;  
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;  
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite; 
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% { 
    -webkit-transform: rotate(0deg) scale(0.8); 
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% { 
    -webkit-transform: rotate(360deg) scale(1.2); 
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% { 
    -webkit-transform: rotate(720deg) scale(0.8); 
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    
  }
  .loading-text {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }


  .table-responsive {
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  .table-responsive table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .table-responsive table th,
  .table-responsive table td {
    white-space: nowrap;
  }
  
  
table {
  border-collapse: collapse;
  width: 100%;
}

/* Style the table header */
th {
  background-color: #f5f5f5;
  font-weight: bold;
  padding: 8px;
  text-align: left;
}

/* Style the table rows */
tr {
  border-bottom: 1px solid #ccc;
}

/* Style the table cells */
td {
  padding: 8px;
}

.cell.label {
  font-weight: bold;
}

.cell.value {
  color: rgb(0, 0, 0);
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}
table {
  border-spacing: 0;
}

th, td {
  padding: 10px;
}